import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "./image"
import Schools from "./schools"
import Logo from './logo'

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "header-bg.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400, quality: 80) {
            src
          }
        }
      }
    }
  `)
  return (
    <div className="uk-hidden@p">
      <header className="uk-background-primary" style={{
        backgroundImage: `url(${data.placeholderImage.childImageSharp.fluid.src})`,
        backgroundSize: `cover`,
        backgroundPosition: `top center`,
        paddingBottom: `30px`
      }}>
        <div className="uk-container">
          <Link to="/" >
            <Image />
          </Link>

        </div>
      </header>
      <div className="uk-container uk-text-center uk-margin uk-margin-top">
        <Schools />
        <Logo />
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
