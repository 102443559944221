import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import '../scss/la-cantine.scss';
import Header from "./header"
import HeaderPrint from "./headerprint"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const uikit = require('uikit');
      const icons = require('uikit/dist/js/uikit-icons.min');
      uikit.use(icons);
    }
  }, []);
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <HeaderPrint siteTitle={data.site.siteMetadata.title} />
      <main>
        <div className="uk-container">
          {children}
        </div>
      </main>
      <footer className="uk-section uk-background-secondary uk-light">
        <div className="uk-container uk-text-center">
          © {new Date().getFullYear()}
          {` `}
          <a href="https://lacasserolette.nc" target="_blank" rel="noopener noreferrer">La Casserolette</a> | Conception et réalisation <a href="https://www.la-fabrik.nc" target="_blank" rel="noopener noreferrer">La Fabrik</a>
        </div>
      </footer>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
