import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Schools from "./schools"

const HeaderPrint = () => {
  const data = useStaticQuery(graphql`
    query {
      headerMobile: file(relativePath: { eq: "header-mobile.png" }) {
        childImageSharp {
          fluid(maxHeight: 200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "logo-mont-dore.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <header style={{ display: 'none' }} className="uk-visible@p uk-margin">
      <div className="uk-container">
        <div className="uk-grid uk-flex uk-flex-middle">
          <div className="uk-width-1-5">
            <img src={data.headerMobile.childImageSharp.fluid.src} alt="logo-la-cantine" />
          </div>
          <div className="uk-width-1-5">
            <img src={data.logo.childImageSharp.fluid.src} alt="logo-mont-dore" />
          </div>
          <div className="uk-width-3-5">
            <Schools />
          </div>
        </div>
      </div>
    </header>
  )
}

HeaderPrint.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderPrint.defaultProps = {
  siteTitle: ``,
}

export default HeaderPrint
