import React from "react"
import Dot from "./dot"
const Schools = () => {
  return (
    <h5>
      Les Coccinelles
      <Dot color="green" />
      La Briqueterie
      <Dot color="purple" />
      Les Dauphins
      <Dot color="#e29a23" />
      Les Eoliennes
      <br />
      Les Fougères
      <Dot color="green" />
      La Farandole
      <Dot color="purple" />
      Jacques Clavel
      <Dot color="#e29a23" />
      Hélène Chaniel
      <br />
      Les Mussandas
      <Dot color="green" />
      Louis Henri Galinié
      <Dot color="purple" />
      Les Bougainvilliers
      <Dot color="#e29a23" />
      Groupe Scolaire Boulari Primaire
      <br />
      Groupe Scolaire Saint Michel
      <Dot color="green" />
      La Rizière
      <Dot color="purple" />
      Vallon Dore Primaire
      <Dot color="#e29a23" />
      La Croix du Sud
    </h5>
  )
}

export default Schools
