import React from "react"

const Dot = ({ color }) => {

  return (
    <span style={{
      backgroundColor: color,
      borderRadius: `1em`,
      height: `.5em`,
      width: `.5em`,
      margin: `.1em 10px`,
      display: `inline-block`
    }}>
    </span>
  )
}


export default Dot
